import { useNuxtApp } from '#app';
import { Service } from '@/types/service';
import { NewsletterPayload, Contact, NearestCountries } from '@/types/general';

export const useGeneralService = () => {
  const { $apifront } = useNuxtApp();

  const subscribeNewsletter = (params: NewsletterPayload) =>
    $apifront<Service<boolean>>('/newsletter/subscribe', {
      method: 'post',
      body: params,
      withCSRF: true,
    });

  const unsubscribeNewsletter = (params: { id: string }) =>
    $apifront<Service<boolean>>('/newsletter/unsubscribe', {
      method: 'post',
      body: params,
      withCSRF: true,
    });

  const changeLanguageNewsletter = (params: { id: string; to: string }) =>
    $apifront<Service<boolean>>('/newsletter/change-language', {
      method: 'post',
      body: params,
      withCSRF: true,
    });

  const confirmNewsletter = (params: { id: string }) =>
    $apifront<Service<boolean>>('/newsletter/confirm', {
      method: 'post',
      body: params,
      withCSRF: true,
    });

  const getAvalaiblePaymentMethods = (params: { currency: string }) =>
    $apifront<Service<{ payments: Array<{ url: string; alt: string }> }>>('/contents/available-payments', { params });

  const verifyEmail = (params: { email: string }) =>
    $apifront<Service<{ status: boolean; suggestions: string[] }>>('/email/verify', {
      method: 'post',
      body: params,
    });

  const postSupportTicket = (params: any) =>
    $apifront<Service<boolean>>('/support/add-ticket', {
      method: 'post',
      body: params,
      withCSRF: true,
    });

  const getSupportPhonesList = () => $apifront<Service<Contact[]>>('/support-phones');

  const getNearestCountries = (params: { lang: string; country: string }) =>
    $apifront<Service<NearestCountries>>('/contents/nearest-countries', { params });

  const getFaqGuide = (params: { lang: string; type: string; sectionSlug?: string; caseSlug?: string }) => {
    let path = `knowledge-base/faq?lang=${params.lang}&type=${params.type}`;

    if (params.sectionSlug) path += `&sectionSlug=${params.sectionSlug}`;
    if (params.caseSlug) path += `&caseSlug=${params.caseSlug}`;

    return $apifront<Service<Array<{ cases: Array<object>; id: string; name: string; slug: string }>>>(path);
  };

  return {
    subscribeNewsletter,
    unsubscribeNewsletter,
    changeLanguageNewsletter,
    confirmNewsletter,
    getAvalaiblePaymentMethods,
    verifyEmail,
    postSupportTicket,
    getSupportPhonesList,
    getNearestCountries,
    getFaqGuide,
  };
};
